<script>
import { required, email } from "vuelidate/lib/validators";
import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
export default {
  data() {
    return {
      user: { 
              userName: "",  userMail: "",  newPassword: "", reNewPassword:""
            },
      submitted: false,
      chnError: null,
      tryingToChange: false,
      isChangeError: false,
      changeSuccess: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    user: {
      userName: { required },
      userMail: { required, email },
      newPassword: { required },
      reNewPassword: { required }
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "fack-backend") 
        {
          this.tryingToChange = true;
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.changepass({
              userMail: this.user.userMail,
              newPassword: this.user.newPassword,
              reNewPassword:this.user.reNewPassword,
              userName:this.user.userName
            })
          );
        } else 
        {
          this.change(this.user);
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-light.png" height="90" alt="logo" />
                          </a>
                        </div>
                        <p class="text-muted">Reset your EINSTUDIO account now.</p>
                      </div>

                      <div class="p-2 mt-5">
                        
                        <form class="form-horizontal" @submit.prevent="tryToReset">

                     
                          <div class="row">
                            <div class="col-4"></div>
                            <div class="col-lg-4">
                             <b-alert variant="danger"  class="mt-4" v-if="notification.message" show dismissible>{{notification.message}}</b-alert>
                              <div class="form-group auth-form-group-custom mb-4">
                              <i class="fa fa-user auti-custom-input-icon"></i>
                              <label for="username">Username</label>
                              <input v-model="user.userName"
                                type="text"
                                class="form-control"
                                id="username"
                                maxlength="20"
                                max="20" :class="{ 'is-invalid': submitted && $v.user.userName.$error }" placeholder="Enter username"/>
                              <div v-if="submitted && !$v.user.userName.required" class="invalid-feedback">Username is required.</div>
                              </div>
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-envelope auti-custom-input-icon"></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.userMail"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email" :class="{ 'is-invalid': submitted && $v.user.userMail.$error }"/>
                                <div v-if="submitted && $v.user.userMail.$error" class="invalid-feedback">
                                  <span v-if="!$v.user.userMail.required">Email is required.</span>
                                  <span v-if="!$v.user.userMail.userMail">Please enter valid email.</span>
                                </div>
                              </div>
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-lock auti-custom-input-icon"></i>
                                <label for="password">Password</label>
                                <input
                                  v-model="user.newPassword"
                                  type="password"
                                  class="form-control"
                                  id="password"
                                  placeholder="Enter password"
                                  maxlength="10"
                                  minlength="8"
                                  max="10"
                                  min="8" :class="{ 'is-invalid': submitted && $v.user.newPassword.$error }"/>
                                <div v-if="submitted && !$v.user.newPassword.required"  class="invalid-feedback">Password is required.</div>
                              </div>
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="fa fa-lock auti-custom-input-icon"></i>
                                <label for="userpassword">Confirm Password</label>
                                <input
                                  v-model="user.reNewPassword"
                                  type="password"
                                  class="form-control"
                                  id="repassword"
                                  placeholder="Enter re-password"
                                  maxlength="10"
                                  minlength="8"
                                  max="10"
                                  min="8" :class="{ 'is-invalid': submitted && $v.user.reNewPassword.$error }"/>
                                <div v-if="submitted && !$v.user.reNewPassword.required" class="invalid-feedback">Password is required.</div>
                              </div>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Reset</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2022 EINSTUDIO.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>